import React, { useEffect, useState } from "react"
import Navbar from "../components/navbar"
import Footer from "../components/footer"
import EventBlock from "../components/EventBlock"
import SEO from "../components/seo"
import "../styles/calendar.css"
import Loading from "../images/loading.gif"
const Calendar = () => {
  const [loading, setLoading] = useState(false)
  const [gigData, setGigData] = useState([])
  const [fetchError, setFetchError] = useState(false)
  const title = "Tour Dates, Shows, and Events"
  const description =
    "Upcoming events, shows, and tour dates for Boy Golden and The Church of Better Daze. Our calendar has everything you need to know!"

  useEffect(() => {
    setLoading(true)

    fetch(
      `https://rest.bandsintown.com/artists/id_15495391/events/?app_id=675e9674c95fdeb12a2b32718624358d`
    )
      .then(function (res) {
        return res.json()
      })
      .then(res => {
        setGigData(res)
        setLoading(false)
      })
      .catch(err => {
        console.log("Error Calling Events API", err)
        setLoading(false)
        setFetchError(true)
      })
  }, [])

  return (
    <>
      <SEO title={title} description={description} />

      <div className="cal-page-container  ">
        <Navbar />
        <div className="cal-flexBox " style={{ minHeight: "100vh" }}>
          <div className=" cal-container tacky-border2  bg-secondary">
            <h1 className="txt-white center-text txt-shadow2">
              Upcoming Church Events
            </h1>
            {gigData &&
              !loading &&
              gigData?.map((show, i) => {
                return <EventBlock event={show} key={`event-${i + 1}`} />
              })}

            {loading && !fetchError && !gigData.length && (
              <div className="center-text">
                <h5 className="txt-highlight">Loading shows...</h5>
                <img className="faces" src={Loading} alt="loading gif"></img>
              </div>
            )}

            {fetchError && !loading && (
              <h6 className="txt-highlight center-text txt-shadow2">
                <a
                  href="https://www.bandsintown.com/a/15495391-boy-golden"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  See Upcoming Performances Here!
                </a>
              </h6>
            )}

            <br />
            <h6 className="txt-highlight center-text txt-shadow2">
              <a href="https://laylo.com/boygolden" target="_blank">
                Join our congregation and never miss a beat!{" "}
              </a>
            </h6>
          </div>
        </div>
        <Footer />
      </div>
    </>
  )
}

export default Calendar
